body {
  background-color: rgba(21,21,49,1);
  color: white;
}

@font-face {
  font-family: SpaceMono;
  src: url(./assets/fonts/SpaceMono-Regular.ttf);
  font-weight: normal;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  margin-top: 8rem;
  margin-bottom: 8rem;
  font-family: SpaceMono;
}

h1 {
  font-style: italic;
}

p {
  color: rgb(216, 216, 216);
  line-height: 28px;
}